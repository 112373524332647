import React, {
	createContext,
	FC,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { IUserProps } from '../common/data/userDummyData';
import UserImage from '../assets/img/wanna/wanna1.png';
import UserImageWebp from '../assets/img/wanna/wanna1.webp';

import ThemeContext from './themeContext';

export interface IAuthContextProps {
	user: string;
	setUser?(...args: unknown[]): unknown;
	userData: Partial<IUserProps>;
	loginErrormsg: string;
	setloginErrormsg: Function;
	location: any;
	setlocation: Function;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}

export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(localStorage.getItem('userAuthToken') || '');
	const [userData, setUserData] = useState<Partial<IUserProps>>({
		isLoading: true,
	});
	const [loginErrormsg, setloginErrormsg] = useState('');
	const navigate = useNavigate();
	const { setparentLoading } = React.useContext(ThemeContext);
	const [location, setlocation] = useState({
		access: false,
		errromsg: '',
		longitude: 0,
		latitude: 0,
	});

	// console.log(parentLoading);
	// console.log(userData);

	const getProfile = (retryCount = 0) => {
		setparentLoading(true);
		fetch(`${process.env.REACT_APP_API_URL}user/profile`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${user}`,
				'Content-Type': 'application/json',
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setparentLoading(false);
				if (data.statuscode === 200) {
					setUserData({
						isLoading: false,
						fullkyc: true,
						fulldata: data.data,
						id: data.data.Mobile,
						username: data.data.Mobile,
						token: user,
						name: data.data.Name,
						main_bal: data.data.MainBal,
						aeps_bal: data.data.AepsBal,
						surname: '',
						position: data.data.Role,
						email: data.data.Name,
						src: UserImage,
						srcSet: UserImageWebp,
						color: 'primary',
					});
				} else if (data.statuscode === 201) {
					setUserData({
						isLoading: false,
						fullkyc: false,
						fulldata: data.data,
						id: data.data.Mobile,
						username: data.data.Mobile,
						token: user,
						name: data.data.Name,
						main_bal: data.data.MainBal,
						aeps_bal: data.data.AepsBal,
						surname: '',
						position: data.data.Role,
						email: data.data.Name,
						src: UserImage,
						srcSet: UserImageWebp,
						color: 'primary',
					});
				} else {
					setloginErrormsg(data.message);
					setUser('');
					setUserData((pre) => ({ ...pre, isLoading: true }));
					navigate(`/`);
				}
			})
			.catch((error) => {
				setloginErrormsg(error.message);
				setparentLoading(false);
				setUser('');
				setUserData((pre) => ({ ...pre, isLoading: true }));
				if (retryCount <= 3) {
					getProfile(retryCount + 1);
				} else {
					navigate(`/`);
				}
			});
	};

	const deleteSocket = useCallback(() => {
		const socket = new WebSocket('ws://localhost:3000/ws');
		socket.close();
	}, []);

	useEffect(() => {
		deleteSocket();
		if (user !== '') {
			getProfile();
		} else {
			setUser('');
			setUserData((pre) => ({ ...pre, isLoading: true }));
		}
		localStorage.setItem('userAuthToken', user);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, navigate]);

	const value = useMemo(
		() => ({
			user,
			setUser,
			userData,
			loginErrormsg,
			setloginErrormsg,
			location,
			setlocation,
		}),
		[user, userData, loginErrormsg, location],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
