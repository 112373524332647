import PropTypes from 'prop-types';
import React, { FC, ReactNode, createContext, useLayoutEffect, useMemo, useState } from 'react';
import useDeviceScreen from '../hooks/useDeviceScreen';

export interface IThemeContextProps {
	asideStatus: boolean;
	darkModeStatus: boolean;
	fullScreenStatus: boolean;
	leftMenuStatus: boolean;
	mobileDesign: boolean;
	rightMenuStatus: boolean;
	rightPanel: boolean;
	parentLoading: boolean;
	wholePageCrash: boolean;
	webData: any;
	hostname: string;
	setparentLoading: (value: ((prevState: boolean) => boolean) | boolean) => void;
	setAsideStatus: (value: ((prevState: boolean) => boolean) | boolean) => void;
	setDarkModeStatus: (value: ((prevState: boolean) => boolean) | boolean) => void;
	setFullScreenStatus: (value: ((prevState: boolean) => boolean) | boolean) => void;
	setLeftMenuStatus: (value: ((prevState: boolean) => boolean) | boolean) => void;
	setRightMenuStatus: (value: ((prevState: boolean) => boolean) | boolean) => void;
	setRightPanel: (value: ((prevState: boolean) => boolean) | boolean) => void;
}
const ThemeContext = createContext<IThemeContextProps>({} as IThemeContextProps);

interface IThemeContextProviderProps {
	children: ReactNode;
}
export const ThemeContextProvider: FC<IThemeContextProviderProps> = ({ children }) => {
	const deviceScreen = useDeviceScreen();

	let { hostname } = window.location;
	const parts = hostname.split('.');

	if (parts.length > 2) {
		const [secondLastPart, lastPart] = parts.slice(-2);

		// Check if the last two parts are 'co' and 'in' (for '.co.in')
		if (lastPart === 'in' && secondLastPart === 'co') {
			hostname = parts.slice(-3).join('.'); // Keep '.co.in'
		} else {
			hostname = parts.slice(1).join('.'); // Remove subdomain
		}
	}

	// Set live domain if we are on localhost
	hostname = hostname === 'localhost' ? 'paydeer.co.in' : hostname;
	const [wholePageCrash, setwholePageCrash] = useState(false);
	// @ts-ignore
	const mobileDesign = deviceScreen?.width <= process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE;
	const [webData, setwebData] = useState({});
	const [darkModeStatus, setDarkModeStatus] = useState(
		localStorage.getItem('facit_darkModeStatus')
			? localStorage.getItem('facit_darkModeStatus') === 'true'
			: process.env.REACT_APP_DARK_MODE === 'true',
	);

	useLayoutEffect(() => {
		localStorage.setItem('facit_darkModeStatus', darkModeStatus.toString());
	}, [darkModeStatus]);

	const [parentLoading, setparentLoading] = useState(false);
	const [fullScreenStatus, setFullScreenStatus] = useState(false);

	const [leftMenuStatus, setLeftMenuStatus] = useState(false);
	const [rightMenuStatus, setRightMenuStatus] = useState(false);
	const [asideStatus, setAsideStatus] = useState(
		localStorage.getItem('facit_asideStatus')
			? localStorage.getItem('facit_asideStatus') === 'true'
			: // @ts-ignore
			  deviceScreen?.width >= process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE,
	);

	const [rightPanel, setRightPanel] = useState(false);

	useLayoutEffect(() => {
		// @ts-ignore
		if (deviceScreen?.width >= process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE) {
			if (localStorage.getItem('facit_asideStatus') === 'true') setAsideStatus(true);
			setLeftMenuStatus(false);
			setRightMenuStatus(false);
		}
		return () => {
			setAsideStatus(false);
		};
	}, [deviceScreen.width]);

	const fetchWebdata = (retrycount = 1) => {
		fetch(`${process.env.REACT_APP_API_URL}whitelable/mydata`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				domain: hostname,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.statuscode === 200) {
					setwebData(data.data);
					localStorage.setItem('whlogo', data.data.logo);
				} else {
					setwholePageCrash(true);
				}
			})
			.catch(() => {
				if (retrycount <= 3) {
					fetchWebdata(retrycount + 1);
				} else {
					setwholePageCrash(true);
				}
			});
	};

	useLayoutEffect(() => {
		fetchWebdata();
		localStorage.setItem('facit_asideStatus', asideStatus?.toString());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [asideStatus]);

	const values: IThemeContextProps = useMemo(
		() => ({
			mobileDesign,
			darkModeStatus,
			setDarkModeStatus,
			fullScreenStatus,
			setFullScreenStatus,
			asideStatus,
			setAsideStatus,
			leftMenuStatus,
			setLeftMenuStatus,
			rightMenuStatus,
			setRightMenuStatus,
			rightPanel,
			setRightPanel,
			parentLoading,
			setparentLoading,
			webData,
			hostname,
			wholePageCrash,
		}),
		[
			asideStatus,
			darkModeStatus,
			fullScreenStatus,
			leftMenuStatus,
			mobileDesign,
			rightMenuStatus,
			rightPanel,
			parentLoading,
			webData,
			hostname,
			wholePageCrash,
		],
	);

	return <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>;
};
ThemeContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ThemeContext;
