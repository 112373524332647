import React, { useContext, useEffect, lazy, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import { useFullscreen } from 'react-use';
import ThemeContext from '../contexts/themeContext';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';

const AsideRoutes = lazy(() => import('../layout/Aside/AsideRoutes'));
const Wrapper = lazy(() => import('../layout/Wrapper/Wrapper'));

const App = () => {
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
			document.documentElement.setAttribute('data-bs-theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
			document.documentElement.removeAttribute('data-bs-theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	return (
		<ThemeProvider theme={theme}>
			<div
				ref={ref}
				className='app'
				style={{
					backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
					zIndex: fullScreenStatus ? 1 : undefined,
					overflow: fullScreenStatus ? 'scroll' : undefined,
				}}>
				<AsideRoutes />
				<Wrapper />
			</div>
		</ThemeProvider>
	);
};

export default App;
